* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

a {
  font-size: 18px !important;
  color: #9e9e9e;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.active > .page-link,
.page-link.active {
  background-color: #01b9eb !important;
  color: white !important;
  border: none !important;
  padding: 8px 15px;
}

.page-link {
  padding: 8px 15px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #74788D !important;
}

#pageDropDown {
  background-color: transparent !important;
  color: black;
  border: 1px solid #a6b0cf;
  width: 70px !important;
}

/* .dropdown-toggle::after {
  margin-left: 1.255em !important;
  border-top: 0.4em solid !important;
} */

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 5px dashed;
  border-top: 5px solid \9;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}


::placeholder {
  color: #b0b0b0 !important;
  font-weight: 500 !important;
}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 295px;
  max-width: 295px;
  color: #625151;
  margin-left: -295px;
  z-index: 1;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.nav-link {
  color: #9e9e9e !important;
  font-weight: 500 !important;
}

li a.dropdown-toggle::after {
  display: none;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll !important;
}

.side-menu::-webkit-scrollbar {
  width: 3px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #babbbb;
}

.border-radius {
  border-radius: 30px;
}

.active-item {
  color: #01b9eb !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 0px !important;
  margin-left: 0;
  height: 100vh;
  overflow-y: scroll;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 150px;
  height: 150px;
  padding: 4px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.thumb2 {
  display: inline-flex;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: 150px;
  padding: 4px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.thumb:hover .thumbInner {
  opacity: 0.7;
}
.thumb2:hover .thumbInner {
  opacity: 0.7;
}

.thumb:hover .middle {
  opacity: 1;
}

.thumb2:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.w-md-100 {
  height: 94vh;
  margin-top: 3vh;
}

.thead-light {
  white-space: nowrap;
}

.widthperRem{
  width: 2.5rem !important;
}

@media only screen and (max-width: 500px) {
  body {
    overflow-x: hidden;
  }

  .height-100 {
    min-height: 100vh !important;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: none;
    position: relative;
    left: 68%;
  }
}

.width-50 {
  width: 50px;
  height: 50px;
}
.width-100 {
  width: 70px;
  height: 70px;
}
.width-150 {
  width: 120px;
  height: 120px;
}

.width-25 {
  width: 35px;
  height: 35px;
}

.mail-icon {
  width: 30px;
}

.wordbreak {
  word-wrap: break-word;
}

.page-content{
  min-height: 91vh;
}

.btn-light-secondary{
  background-color: #E1E4E6 !important;
  color: #7E8299 !important;
}

.btn-close{
  background:transparent url("../public/assets/editpermissionclose.svg") center/4em auto no-repeat !important;
}