body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

.border-light-blue {
  border: 1px solid #e4e6ef !important;
}

.border-light-primary{
  border: 1px solid #A6B0CF !important;
}

.shadow-box{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.fw-600 {
  font-weight: 600 !important;
}

.font-blue {
  color: #a1a5b7 !important;
}

.row {
  --bs-gutter-x: 0 !important;
}

.text-info-blue {
  color: #01b9eb;
}

.text-gray {
  color: #8c9196;
  font-weight: 600;
}
.text-grey-light {
  color: #5A687A;
  font-weight: 300 !important;
} 

.heading-1 {
  font-size: 60px;
}

.form-check-input[type="checkbox"] {
  height: 24px;
  width: 24px;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked[type=radio]{
  background-color: #01b9eb !important;
  border: #01b9eb !important;
  box-shadow: none !important;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #01b9eb !important;
  border: #01b9eb !important;
  box-shadow: none !important;
}

.btn-blue {
  background-color: #01b9eb !important;
}

.videoImage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.widthVideoImage{
  width: 60px;
  height: 40px;
  border-radius: 5px;
}



.widthVideoImageBoth{
  width: 60px;
  height: 40px;
}
.widthVideoImage1{
  width: 30px;
  height: 40px;
  border-radius: 5px 0px 0px 5px;
}

.widthVideoImage2{
  width: 30px;
  height: 40px;
  border-radius: 0px 5px 5px 0px;
}

.sourceVideo{
  height: 700px !important;
}



input,
input:focus {
  outline: none;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bg-light-blue {
  background-color: #f5f8fa;
}

.rounded-5 {
  border-radius: 5px;
}

.bg-light-gray {
  background-color: #f4f7fe;
}

.fw-medium {
  font-weight: 600;
}

.btn-success-green {
  color: #16c35b;
  background: #e8fff3;
  font-weight: 600;
}

.btn-danger-red {
  background: #ffe2e5;
  color: #e80000;
  font-weight: 600;
}

.btn-warning-yellow {
  background: #f9efe1;
  color: #ff9900;
  font-weight: 600;
}

.btn-blue-info {
  background: #ebf4fa;
  color: #009ef7;
  font-weight: 600;
}

.align-self-center .align-self-center td {
  align-self: center !important;
}

.text-blue {
  color: #2d3748 !important;
  font-weight: 500;
}

.border-gray {
  border: 1px solid #c4c4c4;
}

.btn-red {
  color: white !important;
  background-color: #ea4359 !important;
}

.dropzone {
  min-height: 200px;
  border: 2px dashed #c4cad0;
  border-radius: 6px;
}

.dropzone .dz-message {
  font-size: 24px;
  width: 100%;
}
.dropzone .dz-message:focus {
  outline: none;
}

.dropzone-sec {
  min-height: 100px;
  border: 2px dashed #01b9eb;
  background-color: #f1faff;
  border-radius: 6px;
}

.radio-class {
  border: 1px dashed #e4e6ef;
  border-radius: 6px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  border-bottom: 2px solid #cbd2d9 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #01b9eb !important;
  color: #01b9eb !important;
  font-weight: 700;
}

.login-image{
  height: 94vh;
}

@media (max-width: 1200px) {
  .heading-1 {
    font-size: 25px;
  }
}





/* home page css */
/* ================================== 
         CSS Index Start             
=====================================

        01. Default CSS
        02. Text change Css
        03. Navbar Css
        04. About section Css
        05. how we work section Css
        06. best Feature section Css
        07. blog section Css
        08. contact section Css
        09. Media Query

=========== CSS Index End ============*/

/* =====================================
  =    02. Text change Css statrt      =
========================================*/
.text-blue1 {
  color: #01b9eb !important;
}

.font-bold {
  font-weight: 700;
}

.title-text {
  font-size: 42px !important;
}

/*======= Text change Css End =========== */
/* =====================================
 =        03. Navbar Css          =
========================================*/
.nav-links {
  color: #5a5a5a !important;
  font-weight: 400 !important;
  font-size: 21px;
  position: relative;
}

.nav-links:hover {
  color: #01b9eb !important;
  font-weight: 700 !important;
  animation-duration: all 3s;
}

.nav-links:hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 10px;
  left: 40%;
  text-align: center;
  border-radius: 100%;
  border-bottom: 10px solid #01b9eb;
  animation-duration: all 3s;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-light .navbar-toggler {
  border: 3px solid #01b9eb !important;
}

.navbar-toggler:focus {
  box-shadow: 0px 0px 10px #01b9eb !important;
}

/*=======  Navbar Css End =========== */
/* =====================================
 =      04. About section Css       =
========================================*/
.about-sec-h1 {
  width: 1300px;
  margin: auto;
  line-height: 1.5;
  font-size: 60px;
}

.about-sec-p {
  width: 1000px;
  margin: auto;
  line-height: 2;
  color: #5a5a5a;
  font-weight: 500;
  font-size: 24px;
}

.btn-blue1 {
  background-color: #01b9eb;
  border-radius: 20px;
  padding: 26px 65px;
}

.video-img {
  width: 90px;
}

/*=======About section Css End =========== */
/* =====================================
 =    05. how we work section Css     =
========================================*/
.work-sec-p {
  width: 1000px;
  margin: auto;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #5a5a5a;
  line-height: 1.6;
}

.border-box {
  width: 50%;
  height: 20px;
  position: absolute;
  top: 20%;
}

/*======= how we work section Css End =========== */
/* =====================================
 =   06. best Feature section Css     =
========================================*/
.best-feture-section-h1 {
  font-weight: 700;
  font-size: 62px;
  color: #000000;
}

.text-card {
  color: #5a5a5a;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.6;
}

.card-title-h5 {
  color: #01b9eb;
  font-weight: 700;
  font-size: 38px;
}

/*======= best Feature section Css End =========== */
/* =====================================
 =      07. blog section Css       =
========================================*/
.bg-gray {
  background-color: #f4f4f4;
}

.blog-sec-h1 {
  width: 620px;
  font-size: 60px;
  color: #000000;
  line-height: 1.5;
}

.blog-sec-p {
  color: #5a5a5a;
  font-weight: 500;
  font-size: 25px;
  width: 600px;
  line-height: 2;
}

.hr-line {
  width: 90%;
  border: 1px solid #0a0a0a !important;
  margin: auto;
}

.text_input {
  width: 100px;
  border-radius: 7px;
}
.text_input2 {
  width: 20px;
  border-radius: 7px;
}

.right_prefix {
  border: 1px solid #A6B0CF;
  border-radius: 5px 0px 0px 5px;
}

.prefix_right {
  border: 1px solid #A6B0CF;
  border-radius: 0px 5px 5px 0px;
}

li::marker {
  color: #757677;
  font-size: 1.4em;
}

/*======= blog section Css End =========== */
/* =====================================
 =   08. contact section Css start    =
========================================*/
.contact-sec-h1 {
  font-size: 50px;
}

.contact-sec-p {
  width: 400px;
  color: #5a5a5a;
  font-weight: 500;
  font-size: 25px;
  line-height: 2;
}

.email-p {
  color: #2e2e2e;
  font-weight: 500;
  font-size: 22px;
}

.contact-sec-link {
  color: #2e2e2e !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.contact-sec-link:hover {
  color: #01b9eb !important;
}

.form-select{
  width: auto !important;
  display: inline !important;
  background-color: transparent !important;
}

/*======= contact section Css End =========== */
/* =====================================
 =       09. Media Query Start        =
========================================*/
@media screen and (max-width: 1200px) {
  .hero-sec-img {
    width: 300px;
  }

  .login-image{
    height: 70vh;
  }
}

@media screen and (max-width: 1400px) {
  .about-sec-h1 {
    width: 100%;
  }

  .about-sec-p {
    width: 100%;
    font-size: 20px;
  }

  .work-sec-p {
    width: 100%;
  }

  .blog-sec-h1 {
    width: 100%;
    font-size: 30px;
  }

  .blog-sec-p {
    width: 100%;
  }

  .contact-sec-p {
    width: 100%;
  }

  .login-image{
    height: 75vh;
  }
}

@media screen and (max-width: 1000px) {
  .about-sec-h1 {
    font-size: 25px;
  }

  .best-feture-section-h1 {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
  }

  .text-card {
    font-size: 18px;
  }

  .card-title-h5 {
    font-size: 25px;
  }
  .login-image{
    height: 60vh;
  }
}

@media screen and (max-width: 440px) {

  .login-image{
    height: 40vh;
  }

}

@media screen and (max-width: 285px) {
  .video-img {
    width: 70px;
  }

  .login-image{
    height: 30vh;
  }

  .btn-blue1 {
    padding: 20px 50px;
  }
}

/*======= Media Query End =========== */
